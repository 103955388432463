import i18n from '@/libs/i18n'
import useList from '@/views/hooks/useList'
import store from '@/store'

export default function useClientsList() {
  // Table Handlers
  const tableColumns = [
    { label: i18n.t('client.list.column.name'), key: 'nom', sortable: true },
    { label: i18n.t('client.list.column.city'), key: 'ville', sortable: true },
    { label: i18n.t('client.list.column.contactEmail'), key: 'contactEmail', sortable: true },
    { label: i18n.t('client.list.column.actions'), key: 'actions' },
  ]

  return {
    tableColumns,
    ...useList({
      fetchAction: () => store.dispatch('client/fetchClients'),
      deleteActionName: 'client/deleteClient',
    }),
  }
}
